export const GET_AGENCY = "GET_AGENCY";
export const GET_AGENCY_HISTORY = "GET_AGENCY_HISTORY";

export const GET_UNVERIFIED_AGENCY = "GET_UNVERIFIED_AGENCY";
export const GET_AGENCY_WISE_HOST = "GET_AGENCY_WISE_HOST";

export const CREATE_NEW_AGENCY = "CREATE_NEW_AGENCY";

export const ACCEPT_DECLINE_AGENCY = "ACCEPT_DECLINE_AGENCY";

export const SET_CREATE_AGENCY_DONE = "SET_CREATE_AGENCY_DONE";

export const BLOCK_UNBLOCK_SWITCH_AGENCYUSERS =
  "BLOCK_UNBLOCK_SWITCH_AGENCYUSERS";

export const UNSET_CREATE_AGENCY_DONE = "UNSET_CREATE_AGENCY_DONE";

export const SET_UPDATE_AGENCY_DONE = "SET_UPDATE_AGENCY_DONE";

export const UNSET_UPDATE_AGENCY_DONE = "UNSET_UPDATE_AGENCY_DONE";

export const OPEN_AGENCY_DIALOG = "OPEN_AGENCY_DIALOG";

export const CLOSE_AGENCY_DIALOG = "CLOSE_AGENCY_DIALOG";

export const EDIT_AGENCY = "EDIT_AGENCY";
export const EDIT_AGENCY_COIN = "EDIT_AGENCY_COIN";

export const ENABLE_DISABLE_AGENCY = "ENABLE_DISABLE_AGENCY";

export const GET_AGENCY_DROPDOWN = "GET_AGENCY_DROPDOWN";

export const REDEEM_ENDABLED_SWITCH_AGENCY = "REDEEM_ENDABLED_SWITCH_AGENCY";
export const REDEEM_ENDABLED_SWITCH_HOST = "REDEEM_ENDABLED_SWITCH_HOST";
